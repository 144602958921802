import React from 'react';
import ContactsBlock from "../components/contactsBlock";
import SocialBlock from "../components/socialBlock";
import {Col, Row} from "shards-react";
import PageTitle from "../components/pageTitle";
import {CSSTransition, TransitionGroup} from "react-transition-group";

const SideBarTemplate = props => {
    return (
        <TransitionGroup>
            <CSSTransition
                key={props.location.key}
                classNames="dialog"
                appear
                in
                unmountOnExit
                timeout={300}
            >
                <Row>
                    <Col sm={12} lg={12}>
                        <PageTitle
                            title={props.titleInfo}
                            breadcrumbs={props.breadCrumbs}
                            img={props.titleImage}
                        />
                    </Col>
                    <Col sm={12} md={9}>
                        {props.children}
                    </Col>
                    <Col sm={12} lg={3} className={'sidebar'}>
                        <ContactsBlock/>
                        <SocialBlock/>
                    </Col>

                </Row>
            </CSSTransition>
        </TransitionGroup>
    );
};


export default SideBarTemplate;
