import React from 'react';
import Slider from 'react-slick';
import {Col,Row} from "shards-react";
import {useHistory} from "react-router-dom";


const MainPageSlider = props => {
    const  settings = {
        dots: true,
        infinite: true,
        speed: 500,
        slidesToShow: 4,
        slidesToScroll: 1
    };
    const history = useHistory();

    const sliders = [
        // {img:'../images/med/partners/scanlife.png',title:'ScanLife',url:'',country:'Украина'},
        {img:'../images/med/partners/austria_logo.jpg',title:'WIENER PRIVATKLINIK',url:'/clinics/wiener_privatklinik',country:'Австрия'},
        {img:'../images/med/partners/finland_logo.jpg',title:'HELENA MEDICAL GROUP',url:'/clinics/helena_medical_group',country:'Финляндия'},
        {img:'../images/med/partners/malta_logo.jpg',title:'SAINT JAMES HOSPITAL',url:'/clinics/saint_james_hospital',country:'Мальта'},
        {img:'../images/med/partners/turkey_logo.jpg',title:'ACIBADEM',url:'/clinics/meditsinskii_tsentr_adzhibadem',country:'Турция'},
        {img:'../images/med/partners/HBG_Medical_Assistance.jpg',title:'HBG Medical Assistance',url:'/clinics/hbg_medical_assistance',country:'Индия'},
        {img:'../images/med/partners/assuta.jpg',title:'Assuta',url:'/clinics/assuta',country:'Израиль'},
        {img:'../images/med/partners/helderberg.jpg',title:'ун. им Гейдельберга',url:'/clinics/universitet_v_geidelberge',country:'Германия'},
        {img:'../images/med/partners/max-grunding.jpg',title:'им Макса Грундига',url:'/clinics/klinika_maksa_grundiga',country:'Германия'},
    ]

    const redirectToClinic = (url) => {
        history.push(url)
    }

    return (
        <Slider {...settings}>
            {sliders.map(el=>{
                return(
                    <div>
                        <img src={el.img}/>
                        <span className={'slider_title'}><button className={'simpleButton'} onClick={(e)=> {
                            redirectToClinic(el.url)
                        }}>{el.title}</button> </span>
                        <span>{el.country}</span>
                    </div>
                )
            })}
        </Slider>

    );
};


export default MainPageSlider;
