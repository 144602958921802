import React from 'react';
import bg_image from "../images/medical-background.webp";
import {Parallax} from 'react-parallax';
import {Link} from "react-router-dom";
import {Col, Row} from "shards-react";
import belestage from '../images/belestage.jpeg';

const PageTitle = ({title, breadcrumbs,img}) => {

    const bread = () => {
        return breadcrumbs?.map((el, i) => {
            return <li key={i}><Link to={el.url}>{el.title} {i < breadcrumbs.length - 1 && ': ' }</Link></li>
        })
    };

    return (
        <Parallax
            blur={{min: -15, max: 15}}
            bgImage={bg_image}
            bgImageAlt="bg_image"
            strength={-200}
            className={'page_title'}
        >
            <Row>
                {img && <Col sm={12} md={1}><img style={{maxWidth:'120px'}} src={img}/></Col>}
                <Col sm={12} md={11}>
                    <h1>{title}</h1>
                    <ul>{bread()}</ul>
                </Col>
            </Row>
        </Parallax>
    );
};

export default PageTitle;
