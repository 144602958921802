import React from 'react';
import ReactDOM from 'react-dom';
import './styles/app.scss';
import App from './router/app';
import reportWebVitals from './reportWebVitals';
import {Container} from "shards-react";

ReactDOM.render(
    <Container className='main' fluid>
        <App/>
    </Container>,
    document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
