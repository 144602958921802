import React, {useState} from 'react';
import CountryPid from "../pages/countryPid";
import Countries from "../pages/countries";
import MainPage from "../pages";
import NotFound from "../pages/404";
import Clinics from "../pages/clinics";
import ClinicPid from "../pages/clinicPid";
import About from "../pages/about";
import clinicsData from '../data/clinics';
import countriesData from '../data/contries';
import logo_new from '../images/logo_new.png';
import {BrowserRouter as Router, Link, Route, Switch} from "react-router-dom";
import SideBarTemplate from "./SideBarTemplate";
import {
    Col,
    Collapse,
    Dropdown,
    DropdownItem,
    DropdownMenu,
    DropdownToggle,
    Nav,
    Navbar,
    NavbarBrand,
    NavbarToggler,
    NavItem,
    Row
} from "shards-react";
import Footer from '../components/footer'

const App = (props) => {
    const [toggleDropdown, setToggleDropdown] = useState(false);
    const [toggleNavbar, setToggleNavbar] = useState(false);
    const [сountriesDropdown, setCountriesDropdown] = useState(false);
    const [titleInfo, setTitleInfo] = useState(null);
    const [breadCrumbs, setBreadCrumbs] = useState(null);
    const [titleImage, setTitleImage] = useState(null);
    const clinics = () => {
        return clinicsData.map((el, i) => {
            return (
                <DropdownItem key={i}><Link to={'/clinics/' + el.url}>{el.title}</Link></DropdownItem>
            )
        })
    }

    const countries = () => {
        return countriesData.map((el, i) => {
            return (
                <DropdownItem key={i}><Link to={'/countries/' + el.url}>{el.title}</Link></DropdownItem>
            )
        })
    }

    return (
        <Router>
            <Navbar className={'nav'} expand="md" sticky={"true"}>
                <NavbarBrand href="/" className={'logo'}><img src={logo_new}/></NavbarBrand>
                <NavbarToggler onClick={e => setToggleNavbar(!toggleNavbar)}/>
                <Collapse open={toggleNavbar} navbar>
                    <Nav navbar>
                        <NavItem>
                            <Link to={'/'} className='nav-link'>Главная</Link>
                        </NavItem>
                        <Dropdown
                            open={toggleDropdown}
                            toggle={e => setToggleDropdown(!toggleDropdown)}
                        >
                            <DropdownToggle nav caret>
                                Клиники за рубежом
                            </DropdownToggle>
                            <DropdownMenu>
                                {clinics()}
                            </DropdownMenu>
                        </Dropdown>
                        <Dropdown
                            open={сountriesDropdown}
                            toggle={e => setCountriesDropdown(!сountriesDropdown)}
                        >
                            <DropdownToggle nav caret>
                                Лечение за рубежом
                            </DropdownToggle>
                            <DropdownMenu>
                                {countries()}
                            </DropdownMenu>
                        </Dropdown>
                        <NavItem>
                            <Link to={'/about/'} className='nav-link'>О компании</Link>
                        </NavItem>
                    </Nav>
                </Collapse>
            </Navbar>
            <Row>
                <Col sm={12} md={12}>
                    <Switch>

                        <Route exact path="/" component={MainPage}/>
                        <SideBarTemplate titleInfo={titleInfo} breadCrumbs={breadCrumbs} titleImage={titleImage}>
                            <Route path="/countries" component={Countries}/>
                            <Route path='/about' render={(props) => {
                                return <About setTitleImage={setTitleImage} setBreadCrumbs={setBreadCrumbs}
                                                   setTitleInfo={setTitleInfo}{...props} />
                            }}/>
                            <Route path='/countries/:id' render={(props) => {
                                return <CountryPid setTitleImage={setTitleImage} setBreadCrumbs={setBreadCrumbs}
                                                   setTitleInfo={setTitleInfo}{...props} />
                            }}/>
                            <Route path="/clinics" component={Clinics}/>

                            <Route path='/clinics/:id' render={(props) => {
                                return <ClinicPid setTitleImage={setTitleImage} setBreadCrumbs={setBreadCrumbs}
                                                  setTitleInfo={setTitleInfo}{...props} />
                            }}/>
                        </SideBarTemplate>
                        <Route path="*" component={NotFound}/>
                    </Switch>
                </Col>
                <Footer/>
            </Row>
        </Router>
    );
};


export default App;
