import React from 'react';
import {Col, Row} from "shards-react";
import {Parallax} from 'react-parallax';


const Advantages = props => {
    return (
        <Parallax
            blur={{min: -15, max: 15}}
            bgImage={'/images/main/diagnostic_bg.png'}
            bgImageAlt="bg_image"
            strength={-200}
            className={'page_title'}
            style={{marginTop:'60px'}}
        >
            <Row className={'advantages'}>
                <Col sm={12} md={6}>
                    <Row>
                        <Col sm={3}>
                            <img src={'/images/step_1.png'}/>
                        </Col>
                        <Col sm={9}>
                            <span>Подробно изучаем Ваш случай</span>
                            <p>
                                Анализируем заключения врачей и другие материалы. Составляем подробную карту пациента.
                                (Данный
                                этап
                                проводится максимально быстро)
                            </p>
                        </Col>
                        <Col sm={3}>
                            <img src={'/images/step_2.png'}/>
                        </Col>
                        <Col sm={9}>
                            <span>Подробно изучаем Ваш случай</span>
                            <p>
                                Анализируем заключения врачей и другие материалы. Составляем подробную карту пациента.
                                (Данный
                                этап
                                проводится максимально быстро)
                            </p>
                        </Col>
                        <Col sm={3}>
                            <img src={'/images/step_3.png'}/>
                        </Col>
                        <Col sm={9}>
                            <span>Подробно изучаем Ваш случай</span>
                            <p>
                                Анализируем заключения врачей и другие материалы. Составляем подробную карту пациента.
                                (Данный
                                этап
                                проводится максимально быстро)
                            </p>
                        </Col>
                    </Row>
                </Col>
                <Col sm={12} md={6}>
                    <Row>
                        <Col sm={3}>
                            <img src={'/images/step_4.png'}/>
                        </Col>
                        <Col sm={9}>
                            <span>Подробно изучаем Ваш случай</span>
                            <p>
                                Анализируем заключения врачей и другие материалы. Составляем подробную карту пациента.
                                (Данный
                                этап
                                проводится максимально быстро)
                            </p>
                        </Col>
                        <Col sm={3}>
                            <img src={'/images/step_5.png'}/>
                        </Col>
                        <Col sm={9}>
                            <span>Подробно изучаем Ваш случай</span>
                            <p>
                                Анализируем заключения врачей и другие материалы. Составляем подробную карту пациента.
                                (Данный
                                этап
                                проводится максимально быстро)
                            </p>
                        </Col>
                        <Col sm={3}>
                            <img src={'/images/step_6.png'}/>
                        </Col>
                        <Col sm={9}>
                            <span>Подробно изучаем Ваш случай</span>
                            <p>
                                Анализируем заключения врачей и другие материалы. Составляем подробную карту пациента.
                                (Данный
                                этап
                                проводится максимально быстро)
                            </p>
                        </Col>
                    </Row>
                </Col>
            </Row>
        </Parallax>
    );
};

export default Advantages;
