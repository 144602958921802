import React from 'react';
import {Card, CardBody, CardHeader} from "shards-react";
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faStar} from '@fortawesome/free-solid-svg-icons';
import {SocialIcon} from 'react-social-icons';


const SocialBlock = props => {
    return (
        <Card className='social'>
            <CardHeader><FontAwesomeIcon className={'icon'} icon={faStar}/> Социальные сети </CardHeader>
            {/*<CardImg src="https://place-hold.it/300x200" />*/}
            <CardBody>
                <SocialIcon url="https://www.instagram.com/accounts/login/"/>
                <SocialIcon url="https://www.facebook.com/ScanLifeInternational/"/>
                <SocialIcon url="https://www.pinterest.com/scanlifecherkassy/"/>
            </CardBody>
        </Card>
    );
};


export default SocialBlock;
