import React, {useRef, useState} from 'react';
import {Col, Row} from "shards-react";
import {
    useHistory
} from "react-router-dom";

//TODO сделать слайдер
const countryes = [
    {name: 'germanyFlag', img: 'germany.png'},
    {name: 'ukraineFlag', img: 'ukraine.svg'},
    {name: 'austriaFlag', img: 'austria.svg'},
    {name: 'finlandFlag', img: 'finland.svg'},
    {name: 'turkeyFlag', img: 'turkey.svg'},
    {name: 'maltaFlag', img: 'malta.svg'},
    {name: 'indiyaFlag', img: 'india.svg'},
    {name: 'izrailFlag', img: 'israel.svg'},
];

const MainPageMap = props => {
    let mapObject = useRef(null);
    const [activeFlagName, setActiveFlag] = useState('maltaFlag');
    const history = useHistory();

    const changeFlag = (flag,url) => {
        let map = mapObject.current.contentDocument;
        map.getElementById(activeFlagName).style.display = "none";
        map.getElementById(flag).style.display = "inline";
        setActiveFlag(flag)
        console.log(flag)
        setTimeout(()=>{
            history.push(url)
        },3000)
    }

    const countryes = [
        {name: 'germanyFlag', img: 'germany.png', title: 'Германия',url:'/countries/Germaniya'},
        // {name: 'ukraineFlag', img: 'ukraine.svg', title: 'Украина'},
        {name: 'austriaFlag', img: 'austria.svg', title: 'Австрия',url:'/countries/Avstriya'},
        {name: 'finlandFlag', img: 'finland.svg', title: 'Финляндия',url:'/countries/Finlyandiya'},
        {name: 'turkeyFlag', img: 'turkey.svg', title: 'Турция',url:'/countries/Turtsiya'},
        {name: 'maltaFlag', img: 'malta.svg', title: 'Мальта',url:'/countries/Malta'},
        {name: 'indiyaFlag', img: 'india.svg', title: 'Индия',url:'/countries/Indiya'},
        {name: 'izrailFlag', img: 'israel.svg', title: 'Израиль',url:'/countries/Izrail'},
    ];
    return (
        <Row className={'mapMainPage'}>
            <Col sm={12}>
                <object ref={mapObject} data="../images/World_location_map_new_v1.svg" type="image/svg+xml"/>
            </Col>
            <Col sm={12} className={'mapButtons'}>
                {countryes.map((el, i) => {
                    return (
                        <button onClick={e => changeFlag(el.name, el.url)}>
                                                <span>
                                                <img src={'../images/flags/' + el.img}/> {el.title}
                                                </span>
                        </button>
                    )
                })}
            </Col>
        </Row>
    );
};

export default MainPageMap;
