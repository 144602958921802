import React from 'react';
import {Col, Row} from 'shards-react';
import {Link} from 'react-router-dom'
const Footer = () => {
    return (
        <Col sm={12} className={'footer'}>
            <Row className={'footer-top'}>
                <Col sm={12} md={3}>
                    <Link to={'asd'} >Лечение в Австрии</Link>
                    <Link to={'asd'} >Лечение в Финляндии</Link>
                </Col>
                <Col sm={12} md={3}>
                    <Link to={'asd'} >Лечение в Индии</Link>
                    <Link to={'asd'} >Лечение в Турции</Link>
                </Col>
                <Col sm={12} md={3}>
                    <Link to={'asd'} >Лечение на Мальте</Link>
                    <Link to={'asd'} >Лечение в Израиле</Link>
                </Col>
                <Col sm={12} md={3}>
                    <Link to={'asd'} >Лечение в Германии</Link>
                    <Link to={'asd'} >Лечение в Укрвине</Link>
                </Col>
            </Row>
            <Row className={'footer-bottom'}>
                <Col sm={12}><img src={'../logo_new.png'}/> © 2020 Все права защищены.</Col>
            </Row>
        </Col>
    )
}

export default Footer