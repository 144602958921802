import React from 'react';

const Clinics = props => {
    return (
        <div>

        </div>
    );
};


export default Clinics;
