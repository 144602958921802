import React, {useEffect} from 'react';
import {Col, Row} from "shards-react";
import {CSSTransition, TransitionGroup} from "react-transition-group";
import logo_new from '../images/logo_new.png';
import {Helmet} from "react-helmet";
const About = props => {
    const {setTitleInfo, setBreadCrumbs, setTitleImage} = props;
    useEffect(()=>{
        setBreadCrumbs([
            {url: '/', title: 'Главная'},
            {url: '#', title: 'О компании ScanLife'}
        ]);

        setTitleInfo('О компании ScanLife')
    },[])
    return (
        <Row fluid>
            <Helmet>
                <title>{'О компании ScanLife'}</title>
            </Helmet>
            <TransitionGroup>
                <CSSTransition
                    key={props.location.key}
                    classNames="dialog"
                    appear
                    in
                    unmountOnExit
                    timeout={300}
                >
                    <Col sm={12} className={'content'}>
                        <div className={'card'}>
                            <h3>Диагностические центры и организация лечения за рубежом</h3>

                                <p>ScanLife – это современный сервис по подбору медицинских решений и организации
                                    лечения за границей в
                                    лучших клиниках мира. Агентство по подбору клиники ScanLife помогает пациентам
                                    профессионально
                                    решать вопросы, касающиеся самого главного – здоровья человека.</p>
                                <p>ScanLife рекомендует диагностику, лечение, реабилитацию и оздоровление в Германии,
                                    Австрии,
                                    Финляндии, Турции, на Мальте – странах, чьи медицинские стандарты давно стали
                                    эталоном.</p>
                                <p>Клиники, которые принимают наших пациентов, мы знаем уже много лет. Мы гарантируем,
                                    что вами будут
                                    заниматься опытные и квалифицированные специалисты. Обследование, диагностика,
                                    лечение, реабилитация
                                    проводятся лучшими врачами, с использованием качественного оборудования мировых
                                    брендов.</p>
                                <p>Лечение в вышеперечисленных странах гарантирует высокие шансы на долгую, здоровую
                                    жизнь с избавлением
                                    не только от осложнений, а и от самих причин болезни. Тяжелые операции, сложные
                                    диагнозы имеют
                                    высокий процент успешности в зарубежных клиниках, в которые мы готовы вас отправить
                                    и обеспечить
                                    сопровождение, начиная от вашего обращения в ScanLife с проблемой - до возвращения
                                    домой после
                                    прохождения лечения в клинике.</p>
                                <p>
                                    <span style={{fontWidth: 'blod'}}>ScanLife предложит комплексные решения, чтобы вы получили:</span>
                                    <ul>
                                        <li>независимое мнение по проблеме от высококлассных специалистов за границей
                                        </li>
                                        <li>доступ к новейшим подходам и методикам в диагностике и выявлении
                                            потенциальных проблем со
                                            здоровьем
                                        </li>
                                        <li>возможность пройти полное обследование, подтвердить или опровергнуть ранее
                                            поставленный
                                            диагноз
                                        </li>
                                        <li>лечение и реабилитацию по ценам ниже среднеевропейских</li>
                                    </ul>
                                </p>

                                <p>
                                    <span style={{fontWidth: 'blod'}}>Мы составим программу лечения и учтём все ваши пожелания. Клиенты ScanLife получают:</span>
                                    <ul>
                                        <li>индивидуальное консультационное сопровождение на всех этапах, начиная с
                                            первого обращения, и до возвращения домой
                                        </li>
                                        <li>лучшую клинику, полностью отвечающую вашим запросам и поставленным вами
                                            задачам
                                        </li>
                                        <li>составление плана лечения и консультацию специалиста из предлагаемой
                                            клиники
                                        </li>
                                        <li>подготовку и отправку документов за границу</li>
                                        <li>организацию поездки на лечение</li>
                                    </ul>
                                </p>
                        </div>
                    </Col>
                </CSSTransition>
            </TransitionGroup>
        </Row>

    );
};


export default About;
