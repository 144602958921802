import React from 'react';
import {Col, Row} from "shards-react";
import MainPageMap from "../components/mainPageMap";
import MainPageSlider from "../components/mainPageSlider";
import {Parallax} from 'react-parallax';
import bg_image from "../images/medical-background.webp";
import Advantages from "../components/advantages";
import {CSSTransition, TransitionGroup} from "react-transition-group";
import {Helmet} from "react-helmet";


const MainPage = props => {

    return (
                <Row>
                    <Helmet>
                        <title>Диагностика и лечение за рубежом ScanLife</title>
                        <meta name="description" content="ScanLife предлагает качественную диагностику и услуги лечения за рубежом | Scanlife | Сканлайф" />
                    </Helmet>
                    <Col sm={12}>
                        <Parallax
                            blur={{min: -15, max: 15}}
                            bgImage={bg_image}
                            bgImageAlt="bg_image"
                            strength={-200}
                            className={'page_title'}
                        >
                            <Row>

                                <Col sm={12} className={'mainPageH'}>
                                    <h1>ScanLife - ЛЕЧЕНИЕ ЗА РУБЕЖОМ</h1>
                                </Col>
                                <Col sm={12}>
                                    <Row>
                                        <Col sm={12} md={7} className="map-block">
                                            <MainPageMap/>
                                        </Col>
                                        <Col sm={12} md={5}>
                                            <Row className={'mainPageBanners'}>
                                                <Col sm={12} md={6}>
                                                    <img src={'../images/ba_1.png'}/>
                                                    <span>Инновационное лечение</span>
                                                </Col>
                                                <Col sm={12} md={6}>
                                                    <img src={'../images/ba_2.png'}/>
                                                    <span>Профессиональная диагностика</span>
                                                </Col>
                                                <Col sm={12} md={6}>
                                                    <img src={'../images/ba_3.png'}/>
                                                    <span>Лучшие<br/> оборудование</span>
                                                </Col>
                                                <Col sm={12} md={6}>
                                                    <img src={'../images/ba_4.png'}/>
                                                    <span>Квалифицированные <br/> врачи</span>
                                                </Col>
                                            </Row>
                                        </Col>

                                    </Row>
                                </Col>
                            </Row>
                        </Parallax>
                    </Col>
                    <Col sm={12}>
                        <Row className={'mainPagePayBlock'}>
                            <Col sm={4}>
                                <img src={'../images/ba_5.png'}/>
                            </Col>
                            <Col sm={8}>
                                <h2>Вы не оплачиваете услуги ScanLife</h2>
                                <p>
                                    Подбор решения и организация поездки не влияют на ваш счет — нашу работу финансируют
                                    медицинские учреждения. Вы оплачиваете исключительно пройденные процедуры в кассу
                                    клиники.
                                </p>
                            </Col>
                        </Row>
                    </Col>
                    <Col sm={12}>
                        <MainPageSlider/>
                    </Col>
                    <Col sm={12}>
                        <Advantages/>
                    </Col>
                </Row>

    );
};



export default MainPage;
