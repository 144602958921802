import React from 'react';

const Countries = props => {
    return (
        <div>

        </div>
    );
};

export default Countries;
