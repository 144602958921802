import React, {useEffect, useState} from 'react';
import {useHistory, withRouter} from "react-router-dom";
import countrysData from "../data/contries";
import {Col, Row} from "shards-react";
import ReactHtmlParser from 'react-html-parser';
import {CSSTransition, TransitionGroup} from "react-transition-group";
import {Helmet} from "react-helmet";

const CountryPid = props => {
    let history = useHistory();
    const {match} = props;
    const [data, setData] = useState(null);

    useEffect(() => {
        let findData = countrysData.find(el => el.url === match.params.id);
        const {setTitleInfo, setBreadCrumbs, setTitleImage} = props;
        if (findData) {
            setData(findData);
            setTitleInfo(findData.title);
            setTitleImage(findData.img);
            setBreadCrumbs([
                {url: '/clinics', title: 'Клиники за рубежом'},
                {url: '#', title: findData.title},
            ]);
        } else {
            history.push('/404')
        }
    }, [props.match.params.id])
    // "https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2378473.6353168925!2d5.365453024088505!3d50.53013986263178!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x47b8ca3fe3f9dd6f%3A0xe1e2cb7adbbef80b!2sClinic%20Bel%20Etage!5e0!3m2!1suk!2sua!4v1619869420721!5m2!1suk!2sua"
    return (
        data && <Row fluid>
            <Helmet>
                <title>{data.title + ' от ScanLife'}</title>
            </Helmet>
            <TransitionGroup>
                <CSSTransition
                    key={props.location.key}
                    classNames="dialog"
                    appear
                    in
                    unmountOnExit
                    timeout={300}
                >
                    <Col sm={12} className={'content'}>
                        <div className={'card'}><p>{ReactHtmlParser(data.desc)}</p></div>
                    </Col>
                </CSSTransition>
            </TransitionGroup>
        </Row>
    );
};


export default withRouter(CountryPid);
