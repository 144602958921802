import React from 'react';
import {Card, CardBody, CardHeader, CardTitle} from "shards-react";

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPhone } from '@fortawesome/free-solid-svg-icons';

const ContactsBlock = props => {
    return (
        <Card  className='contacts'>
            <CardHeader ><FontAwesomeIcon className={'icon'} icon={faPhone} /> Контакты</CardHeader>
            {/*<CardImg src="https://place-hold.it/300x200" />*/}
            <CardBody>
                <CardTitle>Лечение:</CardTitle>
                <a href='tel:+380972012211'>+38 097 201 22 11</a>
                <CardTitle  className='contacts_title' style={{marginTop: '12px'}}>Диагностика:</CardTitle>
                <a href='tel:+380952012211'>+38 095 201 22 11</a>
            </CardBody>
        </Card>
    );
};


export default ContactsBlock;
