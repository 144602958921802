import React from 'react';

const NotFound = props => {
    return (
        <div>
404
        </div>
    );
};

NotFound.propTypes = {

};

export default NotFound;
